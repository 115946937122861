<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-6">
      <form @submit.prevent="handleSubmit">
        <div class="p-fluid p-grid">
          <h2>{{ $t("contact_us.title") }}</h2>
          <div class="p-field p-col-12">
            <p>{{ $t("contact_us.text") }}</p>
          </div>
          <div class="p-field p-col-12">
            <label for="contact-name">{{
              $t("contact_us.field_contact")
            }}</label>
            <InputText
              v-model="contact"
              aria-label="contact-name"
              aria-describedby="contact-name-error"
              :class="{ 'p-invalid': !!errors['contact'] }"
            />
            <small
              v-if="!!errors['contact']"
              id="contact-name-error"
              class="p-error"
              role="alert"
              aria-live="polite"
              >{{ $t("contact_us.field_contact_error") }}</small
            >
          </div>
          <div class="p-field p-col-12">
            <label for="contact-email">{{
              $t("contact_us.field_email")
            }}</label>
            <InputText
              v-model="email"
              aria-label="contact-email"
              aria-describedby="contact-email-error"
              :class="{ 'p-invalid': !!errors['email'] }"
            />
            <small
              v-if="!!errors['email']"
              id="contact-email-error"
              class="p-error"
              role="alert"
              aria-live="polite"
              >{{ $t("contact_us.field_email_error") }}</small
            >
          </div>
          <div class="p-field p-col-12">
            <label for="subject">{{ $t("contact_us.field_subject") }}</label>
            <InputText
              v-model="subject"
              aria-label="subject"
              aria-describedby="subject-error"
              :class="{ 'p-invalid': !!errors['subject'] }"
            />
            <small
              v-if="!!errors['subject']"
              id="subject-error"
              class="p-error"
              role="alert"
              aria-live="polite"
              >{{ $t("contact_us.field_subject_error") }}</small
            >
          </div>
          <div class="p-field p-col-12">
            <label for="message">{{ $t("contact_us.field_message") }}</label>
            <Textarea
              v-model="message"
              aria-label="message"
              aria-describedby="message-error"
              :class="{ 'p-invalid': !!errors['message'] }"
              :auto-resize="true"
              rows="5"
              cols="30"
            />
            <small
              v-if="!!errors['message']"
              id="message-error"
              class="p-error"
              role="alert"
              aria-live="polite"
              >{{ $t("contact_us.field_message_error") }}</small
            >
          </div>
        </div>
        <div class="p-d-flex p-jc-end">
          <Button type="submit">
            {{ $t("contact_us.button_send") }}
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import { TOAST_LIFE } from "../../config";
import { assertEmail, assertEmpty } from "../../service/assert";
import { GET_ACCOUNT } from "../../store/modules/account/account.getters.type";
import { SEND_SUPPORT_REQUEST } from "../../store/modules/support/support.actions.type";

export default {
  name: "ContactUsForm",
  components: {
    Button,
    InputText,
    Textarea,
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const { t } = useI18n();
    const account = computed(() => store.getters[GET_ACCOUNT]);
    const contact = ref("");
    const email = ref("");
    const subject = ref("");
    const message = ref("");
    const errors = ref([]);

    async function handleSubmit() {
      if (validateInputs()) {
        await store.dispatch(SEND_SUPPORT_REQUEST, {
          company: account.name,
          email: email.value,
          name: contact.value,
          subject: `[BioObserver] ${subject.value}`,
          description: message.value,
        });
        await toast.add({
          severity: "success",
          summary: t("common.success"),
          detail: t("contact_us.toast_success"),
          life: TOAST_LIFE,
        });
        contact.value = email.value = subject.value = message.value = "";
      }
    }

    function validateInputs() {
      errors.value = [];
      if (assertEmpty(contact.value)) {
        errors.value["contact"] = true;
      }
      if (!assertEmail(email.value)) {
        errors.value["email"] = true;
      }
      if (assertEmpty(subject.value)) {
        errors.value["subject"] = true;
      }
      if (assertEmpty(message.value)) {
        errors.value["message"] = true;
      }
      return Object.keys(errors.value).length === 0;
    }

    return {
      contact,
      email,
      message,
      subject,
      errors,
      handleSubmit,
    };
  },
};
</script>
